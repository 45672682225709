import casestudyimg from "../../img/works/casestudyimg.png";

function WorkHeader() {
  return (
    <div className="work-header">
      <div className="header-left">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h1 className="work-header-title">Works</h1>
              <p className="work-header-subtext">
                Dive into my case studies that showcase the depth and precision
                of my thought process. 4 are comprehensive and 1 is
                visual-focused.
              </p>

              <a href="#works" className="btn btnCTA ramadan__project-btn">
                Let's dive in
              </a>
            </div>
            <div className="col-12 col-md-6 work-header-img">
              <img src={casestudyimg} alt="casestudyimg" />

              {/*<h5 className="mt-3 text-center fw-light">
                A less famous UX framework I created
              </h5>*/}
            </div>
          </div>
          {/*<div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h1 className="work-header-title">Works</h1>
              <p className="work-header-subtext">
                Dive into my comprehensive case studies that showcase the depth
                and precision of my thought process. You can also dive in and
                see how "The soft UX" works.
              </p>

              <a href="#works" className="btn btnCTA ramadan__project-btn">
                Check works
              </a>
              <a
                href="https://sharifiology.medium.com/understand-the-soft-ux-ee6b6601411a"
                target="__blank"
                className="btn btnCTAOutline ramadan__project-btn-outline"
              >
                Understand 'The Soft UX'
              </a>
            </div>
            <div className="col-12 col-md-6 work-header-img">
              <a
                href="https://sharifiology.medium.com/understand-the-soft-ux-ee6b6601411a"
                target="__blank"
                className="btn btnCTAOutline ramadan__project-btn-outline"
              >
                <img src={thesoftux} alt="soft workflow" />
              </a>
              <h5 className="mt-3 text-center fw-light">
                A less famous UX framework I created
              </h5>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
}
export default WorkHeader;
