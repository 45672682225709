/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import FancyText from "@carefully-coded/react-text-gradient";
import React from "react";
import funtext from "../vdo/funtext.gif";
import ixdfbadge from "../img/badge/ixdfbadge.svg";
import playstorebadge from "../img/badge/playstorebadge.svg";
import courserabadge from "../img/badge/courserabadge.svg";
import stackexchange from "../img/badge/stackexchange.svg";
import adplist from "../img/badge/adplist.svg";
import headerselfimg from "../img/header/headerselfimg.jpg";
//import talkutube from "../img/badge/talkutube.svg";

import { useLocation, Link } from "react-router-dom";

function Header() {
  const location = useLocation();

  //  const { searchProjects, handleSearch } = this.context;

  return (
    <div className="headerBg">
      <div className="header container-fluid">
        <div className="row ramadan__project-row align-items-center">
          <div className="col-md-7 col-12 ">
            <div className="header__flexOne">
              <div className="header__content">
                <FancyText
                  gradient={{
                    from: "#b8c1cc",
                    to: "#ffffff",
                    type: "linear",
                  }}
                  animate
                  animateDuration={1500}
                >
                  <h1>
                    I UNDERSTAND THE PROBLEM FIRST <br />
                    THEN DESIGN THE SOLUTION
                  </h1>
                </FancyText>
                <p className="header__content-links">
                  UX / Digital Product Designer with 6 years of experience
                </p>
              </div>
              <div className="header__content-badges mb-5">
                <a
                  href="https://www.interaction-design.org/members/hasan-sharif/certificate/course/f7b88d6e-ed1e-40b3-9090-594c619dfef2"
                  target="_blank"
                  rel="noreferrer"
                  className="text-light"
                >
                  {" "}
                  <img
                    src={ixdfbadge}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>

                <a
                  href="https://www.coursera.org/account/accomplishments/professional-cert/PMT5ZYFU3EFX"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src={courserabadge}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>
                <a
                  href="https://adplist.org/mentors/hasan-sharif"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src={adplist}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>
                <a
                  href="https://ux.stackexchange.com/users/142295/hasan"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src={stackexchange}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>
                {/*<a
                  href="https://play.google.com/store/apps/details?id=com.sadaqahmadeeasy.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src={playstorebadge}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>*/}
              </div>
            </div>

            <a href="#works" className="btn btnCTA ramadan__project-btn me-4">
              <i class="fa fa-sort-amount-asc" aria-hidden="true"></i> &nbsp;
              Jump to design works
            </a>

            {/*<Link to="/animations" className="text-light">
              <i class="fa fa-superpowers" aria-hidden="true"></i> &nbsp;
              Animation works
            </Link>*/}
          </div>

          <div className="col-md-5 col-12">
            <div className="header-image-card">
              <img src={headerselfimg} className="rounded" />{" "}
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-4 col-12">
            <div className="glowable-text">
              <div className="card card-2 border-dark mb-3">
                <div className="card-body text-light">
                  <p className="text-light glowable-text">
                    ↳ Took ☕️ while UI Designing for 2 years{" "}
                  </p>
                  <p className="text-light glowable-text">
                    ↳ Navigated the roadmap as a Product Manager for 2 years{" "}
                  </p>
                </div>
                <div className="bg-glow"></div>
              </div>
            </div>
          </div>{" "}
          <div className="col-md-4 col-12">
            <div className="glowable-text">
              <div className="card card-1 border-dark mb-3">
                <div className="card-body text-light">
                  <h5
                    className="card-title glowable-text mb-4"
                    style={{ fontFamily: "Courier Prime" }}
                  >
                    Name: Hasan Sharif
                  </h5>
                  <p className="text-light glowable-text">
                    ↳ Crafting intuitive experiences as a UX Designer for 4
                    years
                  </p>
                  <p className="text-light glowable-text">
                    ↳ Played with{" "}
                    <i className="fa fa-code" aria-hidden="true"></i> Frontend
                    for 2 years
                  </p>
                </div>
                <div className="bg-glow"></div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div className="glowable-text">
              <div className="card card-3 border-dark mb-3">
                <div className="card-body text-light">
                  <p className="text-light glowable-text">
                    ↳ UX Instructor at{" "}
                    {/*<a
                      href="https://www.facebook.com/photo/?fbid=122108819798413753&set=a.122094288830413753"
                      target="__blank"
                    >
                      EDGE Bangladesh
                    </a>*/}
                    <a href="https://edge.gov.bd/" target="__blank">
                      EDGE Bangladesh
                    </a>
                  </p>
                  <p className="text-light glowable-text">
                    ↳ Mentor at{" "}
                    <a
                      href="https://adplist.org/mentors/hasan-sharif"
                      target="__blank"
                    >
                      ADPList
                    </a>
                  </p>
                </div>
                <div className="bg-glow"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center fun-text">
            <h1>
              It wasn't{" "}
              {/*<iframe src="https://lottie.host/embed/91d5ea38-d957-4f60-81d4-85567e2da9af/wa45AOxSaO.json"></iframe>*/}
              <img src={funtext} alt="fun" />
              &nbsp;right?
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
