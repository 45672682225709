/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

//import warningred from "../../img/gif/warningred.gif";

import React from "react";
import ProtoLeft from "./ProtoLeft";
import ProtoRight from "./ProtoRight";
//import MiddleOne from "./MiddleOne";
//import MiddleTwo from "./MiddleTwo";

function Animations() {
  return (
    <div className="attention animations">
      <div className="container">
        <div className="row align-items-center">
          <div className="mb-4">
            <h2 className="text-center text-light"> Me As a Protominator </h2>
            <p className="text-center text-light">Prototype + Animator</p>
          </div>
          <div className="col-md-6">
            {/*<Left />
          <MiddleOne />
          <MiddleTwo />*/}

            {/*<ProtoLeft />*/}
            <ProtoLeft />
          </div>
          <div className="col-md-6">
            <ProtoRight />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Animations;
