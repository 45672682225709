import { Parallax } from "react-scroll-parallax";
import conversionsection from "../../../img/anlytic/conversionsection.png";

function Fluid() {
  return (
    <>
      <Parallax speed={10}>
        <div className="fast attention-two-fluid">
          <a href="https://anlytic.com" target="__blank">
            <div className="card mt-3 p-0">
              <div className="card-body">
                <h2 className="card-title">
                  <i className="fa fa-money" aria-hidden="true"></i> &nbsp;
                  Sales vs UX
                  <p className="card-date mt-1">JUNE. 2024</p>
                </h2>
                <h3 className="card-text">
                  The Conversion-Driven
                  <br />
                  <span style={{ color: "#5690ee" }}>SaaS</span> Feature Section
                </h3>
                <p className="card-subtitle text-light mb-3">
                  This design was shipped based on a collaboration with the
                  marketing and sales team. Check the work live and experience
                  how I played with trade-off there.
                </p>

                <div className="flex flex-row">
                  <a
                    className="btn btnCTA ramadan__project-btn"
                    href="https://anlytic.com"
                    target="__blank"
                  >
                    Live!
                  </a>
                  <a
                    className="text-light ms-3"
                    href="https://adplist.org/mentors/hasan-sharif"
                    target="__blank"
                  >
                    Ask me about the design
                  </a>
                </div>
              </div>
              <div className="card-bottom-img">
                <img src={conversionsection} alt="casestudyimg" />
              </div>
              {/*<div className="card-footer">
              <a
                className="text-light"
                href="https://play.google.com/store/apps/details?id=com.sadaqahmadeeasy.app"
                target="__blank"
              >
                Read now
              </a>
            </div>*/}
            </div>
          </a>
        </div>
      </Parallax>
    </>
  );
}
export default Fluid;
