/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import leftproto from "../../../img/gif/leftproto.gif";

import React from "react";

function ProtoLeft() {
  return (
    <div className="proto-right">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-12">
            <img src={leftproto} alt="" height={"350px"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProtoLeft;
