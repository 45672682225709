/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import rightproto from "../../../img/gif/rightproto.gif";

import React from "react";

function ProtoRight() {
  return (
    <div className="proto-right">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-12">
            <img src={rightproto} alt="" height={"150px"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProtoRight;
