import eightcover from "../../img/eight/eightcover.png";
import anlytictoken from "../../img/eight/anlytictoken.png";
import eightintro from "../../img/eight/eightintro.png";
import eightfirstsketch from "../../img/eight/eightfirstsketch.png";
import eightothercomponents from "../../img/eight/eightothercomponents.png";
import eightfilteroverview from "../../img/eight/eightfilteroverview.png";
import eightsetupsinglefields from "../../img/eight/eightsetupsinglefields.png";
import eightfilterpreview from "../../img/eight/eightfilterpreview.png";
import eightsetupmultiplefields from "../../img/eight/eightsetupmultiplefields.png";
import eightaichatui from "../../img/eight/eightaichatui.png";
import eightsidebarthemes from "../../img/eight/eightsidebarthemes.png";
import eightdashboard1 from "../../img/eight/eightdashboard1.png";
import eightdashboard2 from "../../img/eight/eightdashboard2.png";
import eightintromock from "../../img/eight/eightintromock.png";
import eightlastmock from "../../img/eight/eightlastmock.png";
import eighttitleicon from "../../img/eight/eighttitleicon.png";
import eightaichatuidark from "../../img/eight/eightaichatuidark.png";
import eightaichatuinavy from "../../img/eight/eightaichatuinavy.png";

const ProjectEightData = [
  {
    _ID: "sdqme01.8",
    projectImg: eightcover,
    projectHeading: "A robust filter for a Business Intelligence product",
    projectSubTitle: (
      <>
        In Anlytic, I have been designing one of the core features i.e.,{" "}
        <b>Custom filter</b> for the Integrated Data Environment. During the
        design, I followed a 'Collaboration-first' process where the primary
        stakeholders were the developers so that everyone could use the common
        language and align on requirements. Let's deep dive in!
      </>
    ),
    slug: "anlytic-filter",
    based: "USA",
    tag: "Smart data solutions",
    timeline: <span> 03/2024 - 07/2024</span>,
    projectDetails: (
      <div className="container">
        <div className="row">
          {/*intro */}
          <div className="col-md-12 mb-5">
            <h3>
              In Anlytic, I have been designing one of the core features i.e.,{" "}
              <b>Custom filter</b> for the Integrated Data Environment. During
              the design, I followed a 'Collaboration-first' process where the
              primary stakeholders were the developers so that everyone could
              use the common language and align on requirements. Let's deep dive
              in!
            </h3>
            <p
              className="text-secondary text-center fw-light mt-5 ps-2 pe-2"
              style={{ fontSize: "22px" }}
            >
              <i>Features and screens you can see here are real</i>
            </p>
          </div>

          <div className="col-md-6 col-12 mt-4">
            <h4>
              <u>Task goal</u>
            </h4>
            <p>
              To make the data filtering and filter management flow better for
              every data analyst keeping the data-design philosophy in mind.
            </p>
          </div>
          <div className="col-md-6 col-12 mt-4">
            <h4>
              <u>My role (Senior Software Designer)</u>
            </h4>
            <ul>
              <li>User research</li>
              <li>Maintain communication with the dev team</li>
              <li>Sketch (Pen n Pencil) </li>
              <li>High-fidelity handoff</li>
            </ul>
          </div>
        </div>

        {/*intro screens */}

        <div className="row">
          <div className="col-md-12 text-center mt-5">
            <img src={eightintro} alt="eight-intro" className="w-100" />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Collaboration {">"} Understand {">"} Design
            </p>
          </div>
        </div>

        {/*process */}

        <div className="row" style={{ marginTop: "2rem" }}>
          {/*The challenge */}
          <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
            <h3 className="mb-4 fw-bold">Process</h3>
            <p style={{ lineHeight: "35px", fontSize: "22px" }}>
              <ul>
                <li>
                  Gather requirements from CTO, understand the end goal and
                  vision behind the feature
                </li>
                <li>Talk with data anlyst and gather real insights</li>
                <li>Use filter feature of Tableu, Power BI, Superset</li>
                <li>Find pain-points & propose solutions</li>
                <li>Understand the backend & logic to reduce iteration </li>
              </ul>
            </p>
          </div>
          <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
            <h3 className="mb-4 fw-bold">Timeline</h3>
            <p style={{ lineHeight: "35px", fontSize: "22px" }}>
              As this was designed for an existing client and the public
              instance of the product, I had some flexibility to develop an
              improved solution. Additionally, I was involved in designing other
              features, which occasionally created long breaks that disrupted
              the continuity of the work. Within March to August 2024, I was
              able to deliver the design. Ignoring the breaks, I'd say it took 3
              months!
            </p>
          </div>
          <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
            <h3 className="mb-4 fw-bold">With stakeholders</h3>
            <p style={{ lineHeight: "35px", fontSize: "22px" }}>
              The CTO was the primary stakeholder for gathering feedback. Since
              the development team already had the architecture prepared, I held
              numerous sync meetings with them to refresh and refine the
              ideation process.
            </p>
          </div>
        </div>

        {/*intro mockup*/}

        <div className="row" style={{ marginTop: "0.5rem" }}>
          <div className="col-md-12">
            <div className="title-icon">
              <h3 className="mb-4 fw-bold">User interfaces</h3>
              <img src={eighttitleicon} alt="eighttitleicon" />
            </div>
            <img
              src={eightintromock}
              alt="eightintromock"
              className="w-100 border"
            />
            {/*<p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Very first low fidelity using Balsamiq
            </p>*/}
          </div>
        </div>

        {/*How it looked like - lofi */}

        <div className="row" style={{ marginTop: "5rem" }}>
          <div className="col-md-7">
            <p>
              {" "}
              <b>How it looked like</b>
            </p>
            <h3 style={{ lineHeight: "40px" }}>
              My first solution was presented using the sidebar layout which
              carries the fields of the filter and opens from the right side.
              The end result is completely different than this though. But the
              goal of using the sidebar was to control the focus and preserve
              the context from users' perspective.
            </h3>
            <div
              className="alert alert-warning"
              role="alert"
              style={{ fontSize: "16px", marginTop: "1rem" }}
            >
              Progressive disclosure: Show users only a few of the most
              important options. (NN group)
            </div>
          </div>
          <div className="col-md-5 align-self-center">
            <img
              src={eightfirstsketch}
              alt="eightfirstsketch"
              className="w-100"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Very first low fidelity using Balsamiq
            </p>
          </div>
        </div>

        {/*visual one - table, checkbox modal*/}

        <div className="row" style={{ marginTop: "10rem" }}>
          <div className="col-md-12 text-center mb-5">
            <h3 style={{ lineHeight: "35px" }}>Visual designs</h3>
            <p>
              {" "}
              <b>Click on each image for a clearer view</b>
            </p>
          </div>{" "}
          <div className="col-md-6 text-center">
            <a
              href="https://drive.google.com/file/d/1ed6wWQWRK053ka1e966ZkOaIcN8yhQ7I/view?usp=sharing"
              target="__blank"
            >
              <img
                src={eightfilteroverview}
                alt="eightvisualtable"
                className="w-100 shadow mb-2 rounded"
              />
              <p
                className="text-center text-secondary mb-5"
                style={{ fontSize: "14px", marginTop: ".5rem" }}
              >
                Filter overview (Click to preview ↗)
              </p>
            </a>
          </div>{" "}
          <div className="col-md-6 text-center">
            <a
              href="https://drive.google.com/file/d/1dYK0X97YyH0HANveA9jGfYAt2h3LrKIK/view?usp=sharing"
              target="__blank"
            >
              <img
                src={eightsetupsinglefields}
                alt="eightsetupsinglefields"
                className="w-100 shadow mb-2 rounded"
              />
              <p
                className="text-center text-secondary mb-5"
                style={{ fontSize: "14px", marginTop: ".5rem" }}
              >
                Setup single fields for filter (Click to preview ↗)
              </p>
            </a>
          </div>
          <div className="col-md-6 text-center">
            <a
              href="https://drive.google.com/file/d/1KMmuT5W5w-3k8cUlWS1p7YuS4RbNi4C6/view?usp=sharing"
              target="__blank"
            >
              <img
                src={eightsetupmultiplefields}
                alt="eightsetupmultiplefields"
                className="w-100 rounded shadow mb-2"
              />
              <p
                className="text-center text-secondary mb-5"
                style={{ fontSize: "14px", marginTop: ".5rem" }}
              >
                Setup mutiple fields for the filter (Click to preview ↗)
              </p>
            </a>
          </div>
          <div className="col-md-6 text-center">
            <a
              href="https://drive.google.com/file/d/1xkq4ZNiJqsB1KU4s3AzQTKtDIJ2KqLM6/view?usp=sharing"
              target="__blank"
            >
              <img
                src={eightfilterpreview}
                alt="eightfilterpreview"
                className="w-100 rounded shadow mb-2"
              />
              <p
                className="text-center text-secondary mb-5"
                style={{ fontSize: "14px", marginTop: ".5rem" }}
              >
                Filter preview (Click to preview ↗)
              </p>
            </a>
          </div>
        </div>

        {/*visual one - table, checkbox modal*/}

        {/*AI chat UI*/}
        <div className="row" style={{ marginTop: "5rem" }}>
          {/*survey */}
          <div className="col-md-12 text-center">
            <p>
              {" "}
              <b>AH finally, A.I feature</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              Its all about you ask for an insight then data talks
            </h3>
          </div>{" "}
          <div className="col-md-12 mt-5 text-center">
            <a
              href="https://drive.google.com/file/d/14FWJX5Q43Qtx_eJ8HZISlrGXOwAl6upR/view?usp=sharing"
              target="__blank"
            >
              <img
                src={eightaichatui}
                alt="anlytictoken"
                className="w-75 mb-2"
              />
              <p
                className="text-center text-secondary mb-5"
                style={{ fontSize: "14px", marginTop: "1rem" }}
              >
                Anlytic A.I chart generation (Check prototype ►)
              </p>
            </a>
          </div>
        </div>

        <div className="row" style={{ marginTop: "5rem" }}>
          <div className="col-md-6 text-center">
            <a
              href="https://drive.google.com/file/d/100WiFL64qiYUlAtor1Qud1m9R3XMaSeO/view?usp=sharing"
              target="__blank"
            >
              <img
                src={eightaichatuidark}
                alt="eightaichatuidark"
                className="w-100 mb-2"
              />
              <p
                className="text-center text-secondary mb-5"
                style={{ fontSize: "14px", marginTop: ".5rem" }}
              >
                Filter overview - Dark theme (Click to preview ↗)
              </p>
            </a>
          </div>{" "}
          <div className="col-md-6 text-center">
            <a
              href="https://drive.google.com/file/d/1VGnLmy6id7Z38y0KseyRcLy51eE4gofy/view?usp=sharing"
              target="__blank"
            >
              <img
                src={eightaichatuinavy}
                alt="eightaichatuinavy"
                className="w-100 mb-2"
              />
              <p
                className="text-center text-secondary mb-5"
                style={{ fontSize: "14px", marginTop: ".5rem" }}
              >
                Filter overview - Navy theme (Click to preview ↗)
              </p>
            </a>
          </div>
        </div>

        {/*More involvements - token*/}
        <div className="row" style={{ marginTop: "5rem" }}>
          {/*tokens */}
          <div className="col-md-12 text-center">
            <p>
              {" "}
              <b>More involvements</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              The robust design decision storage I created - TOKEN
            </h3>

            <img
              src={anlytictoken}
              alt="anlytictoken"
              className="w-75 mt-2 mb-2"
            />
            <p
              className="text-center text-secondary mb-5"
              style={{ fontSize: "14px", marginTop: "1rem" }}
            >
              Color tokens
            </p>
          </div>
          {/*sidebar themes */}
          <div className="col-md-12 text-center mt-5">
            <h3 style={{ lineHeight: "35px" }}>
              Switching colors isn't fun rather a solid challenge
            </h3>

            <img
              src={eightsidebarthemes}
              alt="eightsidebarthemes"
              className="w-50 mb-2"
            />
            <p
              className="text-center text-secondary mb-5"
              style={{ fontSize: "14px", marginTop: "1rem" }}
            >
              Sidebar in three different themes
            </p>
          </div>
          <div className="row" style={{ marginTop: "2.5rem" }}>
            <div className="col-md-5 align-self-center">
              <img
                src={eightothercomponents}
                alt="eightothercomponents"
                className="w-100"
              />
              <p
                className="text-center text-secondary"
                style={{ fontSize: "14px", marginTop: "1.5rem" }}
              >
                Sidebar, Popups, Modals, Tables
              </p>
            </div>
            <div className="col-md-7">
              <p>
                {" "}
                <b>Reusable components optimized development workflows </b>
              </p>
              <h3 style={{ lineHeight: "40px" }}>
                By following component-driven methodology in creating the desgin
                system, I have made the robust component library for the Anlytic
                application.
              </h3>
            </div>
          </div>
        </div>

        {/*dashboards */}
        <div className="row" style={{ marginTop: "5rem" }}>
          <div className="text-center mb-4">
            <div className="title-icon">
              <h3 className="mb-1 fw-bold">
                {" "}
                Dashboard UI: Prefessional yet playful
              </h3>
              <img src={eighttitleicon} alt="eighttitleicon" />
            </div>
          </div>{" "}
          <div className="col-md-6 text-center">
            <a
              href="https://drive.google.com/file/d/1WnAS0njwkqAJ7hthgRxTUCpawJNnx-hX/view?usp=sharing"
              target="__blank"
            >
              <img
                src={eightdashboard1}
                alt="eightdashboard1"
                className="w-100 shadow mb-2"
              />
              <p
                className="text-center text-secondary mb-5"
                style={{ fontSize: "14px", marginTop: "1rem" }}
              >
                Dashboard Home (Click to preview ↗)
              </p>
            </a>
          </div>{" "}
          <div className="col-md-6 text-center">
            <a
              href="https://drive.google.com/file/d/1wSu61hdqTyjZJgt5M99BJfccp-mahWtj/view?usp=sharing"
              target="__blank"
            >
              <img
                src={eightdashboard2}
                alt="eightdashboard2"
                className="w-100 shadow mb-2"
              />
              <p
                className="text-center text-secondary mb-5"
                style={{ fontSize: "14px", marginTop: "1rem" }}
              >
                Dashboard - Charts (Click to preview ↗)
              </p>
            </a>
          </div>
          {/*lastmock*/}
          <div className="row" style={{ marginTop: "1.5rem" }}>
            <div className="col-md-12">
              {/*<div className="title-icon">
                <h3 className="mb-4 fw-bold">Dark Versions</h3>
                <img src={eighttitleicon} alt="eighttitleicon" />
              </div>*/}
              <img
                src={eightlastmock}
                alt="eightlastmock"
                className="w-100 border"
              />
            </div>
          </div>
        </div>

        <p className="text-center" style={{ marginTop: "5rem" }}>
          - A big thank you for the time you spent reading this case study -
        </p>
      </div>
    ),
  },
];

export function getProjectEightData() {
  return ProjectEightData;
}
